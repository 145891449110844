import React from "react"
import Layout from "../components/layout/Layout"
import { Pages } from "../pages"
import { useStaticQuery, graphql } from "gatsby"
import { GetAllPostsQuery, MarkdownRemarkFrontmatter } from "../graphql"
import { Container, Row, Col } from "react-bootstrap"
import "../index.scss"
import MyJumbo from "../components/myJumbo/MyJumbo"
import BlogCard from "../components/blog-card/blog-card.component"

const BlogPage: React.FC = () => {
  const data = useStaticQuery<GetAllPostsQuery>(graphql`
    query GetAllPosts {
      allMarkdownRemark(
        filter: {
          frontmatter: { published: { eq: true }, category: { eq: "blog" } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            html
            fields {
              slug
            }
            frontmatter {
              title
              date
              subtitle
              cover {
                childImageSharp {
                  gatsbyImageData(width: 512, height: 512, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout page={Pages.BLOG}>
      <MyJumbo title={"Blog"} content={<></>} />
      <Container fluid>
        <Row>
          {data.allMarkdownRemark?.edges.map((edge) => {
            const to = `${edge.node?.fields?.slug}` || ""
            const { cover, title, subtitle, date } = edge!.node!
              .frontmatter as MarkdownRemarkFrontmatter
            return (
              <Col key={to} xs={12} sm={6} md={4} lg={3} className="mb-3">
                <BlogCard title={title} cover={cover} date={date} slug={to} />
              </Col>
            )
          })}
        </Row>
      </Container>
    </Layout>
  )
}

export default BlogPage
